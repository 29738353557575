<template>
    <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="7">
            <vx-card class="mr-2">
                <div class="file-upload-container">
                    <multiple-file-uploader postURL="http://.." successMessagePath="" errorMessagePath="" headerMessage=""></multiple-file-uploader>

                </div>
                <div class="filter-container">
                    <vs-row>
                        <vs-col vs-align="center" vs-w="4">
                            <div class="form-group has-clear-right">
                                <input type="text" placeholder="Suchen..."  v-model="searchInput" class="form-control mr-2" v-on:keyup.enter="fetchData" autocomplete="off">
                                <vs-button color="primary" type="border" icon="search" @click="fetchData"></vs-button>
                            </div>
                        </vs-col>
                        <vs-col  vs-align="center" vs-w="4">
                            <div class="form-group has-clear-right">
                                <date-range-picker
                                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                        :timePicker="false"
                                        :timePicker24Hour="false"
                                        :showWeekNumbers="false"
                                        :showDropdowns="false"
                                        :autoApply="false"
                                        v-model="dateRange"
                                        @update="updateValues"
                                        @toggle="checkOpen"
                                >
                                    <template v-slot:input="picker" style="min-width: 350px;">
                                        {{ picker.startDate | date}} - {{ picker.endDate | date}}
                                    </template>
                                </date-range-picker>
                                <vs-button color="primary" type="border" icon="close" @click="clearDateRange"></vs-button>
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="4">
                            <div class="ml-4 mt-3 flex">
                                <vs-switch color="success" v-model="with_finished_documents" @change="fetchData()"/> <p class="ml-2">Zugewiesene Dokumente anzeigen</p>
                            </div>
                        </vs-col>
                    </vs-row>
                </div>

                <vs-row class="result-container" >
                    <vs-col  vs-justify="left" vs-align="left" vs-w="2" class="document-item mt-2 p-2" v-for="(file, i) in documents" :class="{'current-edit-document': file.id === currentEditingDocumentId}">

                        <router-link :to="'/document-management/' + file.id" @click="editDocumentDetails(file)" class="document-link">
                            <div>
                                <div class="badges-container">
                                    <vx-tooltip text="Perfekt vorbereitet">
                                        <vs-icon icon="check_circle" class="check_circle" v-if="file.sum_brutto && file.document_date && file.document_number "></vs-icon>
                                    </vx-tooltip>
                                    <vx-tooltip text="Pflichtfelder noch zu füllen">
                                        <vs-icon icon="cancel" class="cancel" v-if="!file.sum_brutto || !file.document_date || !file.document_number "></vs-icon>
                                    </vx-tooltip>
                                  <vx-tooltip text="Bereits zu einer Banküberweisung zugeordnet">
                                        <vs-icon icon="check_circle" class="check_circle" v-if="file.bank_transaction_id"></vs-icon>
                                    </vx-tooltip>
                                </div>
                                <div class="image-container">
                                    <img  :src="file.file_thumb_url">
                                </div>
                                <div class="info-container">
                                    <span class="filename">{{file.original_filename}}</span>
                                    <i class="sum" v-if="file.sum_brutto">{{file.sum_brutto}} €</i>
                                </div>
                            </div>
                        </router-link>
                    </vs-col>
                </vs-row>
              <span></span>
                <button @click="fetchData()" class="mt-5 vs-component vs-button vs-button-primary vs-button-border mr-6">Daten neuladen</button>

              <vs-row class="mt-5">
                <vs-col vs-align="center" vs-w="3">
                  <vs-select v-model="requestedInvoiceMonth" name="month"
                             placeholder="Monat">
                    <vs-select-item value="01" text="01" />
                    <vs-select-item value="02" text="02" />
                    <vs-select-item value="03" text="03" />
                    <vs-select-item value="04" text="04" />
                    <vs-select-item value="05" text="05" />
                    <vs-select-item value="06" text="06" />
                    <vs-select-item value="07" text="07" />
                    <vs-select-item value="08" text="08" />
                    <vs-select-item value="09" text="09" />
                    <vs-select-item value="10" text="10" />
                    <vs-select-item value="11" text="11" />
                    <vs-select-item value="12" text="12" />
                  </vs-select>
                </vs-col>
                <vs-col vs-align="center" vs-w="3">
                  <vs-select v-model="requestedInvoiceYear" name="year"
                             placeholder="Jahr">
                    <vs-select-item value="2021" text="2021" />
                    <vs-select-item value="2022" text="2022" />
                    <vs-select-item value="2023" text="2023" />
                    <vs-select-item value="2024" text="2024" />
                    <vs-select-item value="2025" text="2025" />
                    <vs-select-item value="2026" text="2026" />

                  </vs-select>
                </vs-col>
                <vs-col vs-align="center" vs-w="6">
                  <button @click="getOutgoingInvoice()" class=" vs-component vs-button vs-button-primary vs-button-border">Bezahlte Ausgangsrechnungen abholen</button>
                </vs-col>
              </vs-row>





            </vx-card>

        </vs-col>
        <vs-col vs-type="flex" vs-justify="top" vs-align="top" vs-w="5">
            <vx-card>
                <h1>Details</h1>

                <vs-alert v-show="editedDocument.length === 0" color="warning" icon="new_releases" >
                    <span>Bitte Dokument auswählen</span>
                </vs-alert>
                <vs-row>
                    <div id="document-pdf-viewer"></div>
                </vs-row>
                <div v-if="editedDocument.id">
                    <i v-if="editedDocument.original_filename">{{editedDocument.original_filename}}</i>
                    <vs-alert v-show="editedDocument.bank_transaction_id" color="danger" icon="new_releases" >
                        <span>Dieses Dokument wurde bereits einer Transaktion zugeordnet. Vorsicht vor nachträgliche Änderungen!</span>
                    </vs-alert>


                    <vs-row>
                        <vs-button color="dark" type="filled" class="mr-3 mb-auto" size="small"
                                   target="_blank"
                                   :href="`${$globalUrl}storage/accounting_documents/${editedDocument.file}`"
                                   v-if="editedDocument.file">
                           Dokument herunterladen
                        </vs-button>
                    </vs-row>

                    <vs-row>
                        <vs-col vs-w="6" class="pr-4">
                            <vs-input label="Belegdatum" name="document_date" v-model="editedDocument.document_date" :disabled="editedDocument.bank_transaction_id != null" class="w-full" data-vv-as="Beleg Datum" autocomplete="off" v-validate="'required|date_format:dd.MM.yyyy'"  />
                            <span class="text-danger text-sm" v-if="errors.has('document_date')">{{ errors.first('document_date') }}</span>
                        </vs-col>
                        <vs-col vs-w="6" class="pr-4">
                            <vs-input label="Belegnummer" name="document_number" v-model="editedDocument.document_number" :disabled="editedDocument.bank_transaction_id != null"
                                      @blur="findDuplicateDocuments"
                                      autocomplete="off" class="w-full" data-vv-as="Belegnummer" v-validate="'required'"  />
                            <span class="text-danger text-sm" v-if="errors.has('document_number')">{{ errors.first('document_number') }}</span>
                            <span v-if="duplicateDocumentNumbersCount >= 1" class="text-red">
                                Es wurden <b>{{duplicateDocumentNumbersCount}}</b> gleiche Belegnummer(n) im System gefunden.
                                <span class="pseudo-link text-bold" @click="openDuplicatePopup"><u>Anzeigen</u></span>
                            </span>
                            <vs-popup id="double-document-numbers-popup--modal" title="Doppelte Belegnummern gefunden" :active="isDuplicatePopupOpen" @close="onCloseDuplicatePopup" class="double-document-numbers-popup">

                                <h4>Dubletten zu Belegnummer: {{editedDocument.document_number}}</h4>


                                <vs-table striped :data="duplicateDocumentNumbers" :hoverFlat="true" >
                                    <template slot="thead">

                                            <vs-th>

                                            </vs-th>
                                            <vs-th>
                                                ID
                                            </vs-th>
                                            <vs-th>
                                                Firma
                                            </vs-th>
                                            <vs-th>
                                                Datum
                                            </vs-th>
                                            <vs-th>
                                                Summe
                                            </vs-th>
                                        <vs-th>
                                            Bank Zugewiesen?
                                        </vs-th>
                                            <vs-th>
                                                Erstellt am
                                            </vs-th>

                                    </template>
                                    <template slot-scope="{data}">


                                        <vs-tr class="current-document bg-green-light">

                                            <vs-td>Aktuelles <br>Dokument</vs-td>
                                            <vs-td>{{ editedDocument.id }}</vs-td>
                                            <vs-td>{{ editedDocument.client_id ? editedDocument.client.company_name : 'Keine Firma zugeordnet'  }}</vs-td>
                                            <vs-td>{{ editedDocument.document_date ? editedDocument.document_date : 'Kein Datum hinterlegt'  }}</vs-td>
                                            <vs-td>{{ editedDocument.sum_netto ? floatPointToCommaCurrency(editedDocument.sum_netto) : '-'   }}
                                                /
                                                {{ editedDocument.sum_brutto ? floatPointToCommaCurrency(editedDocument.sum_brutto) : '-'   }}
                                            </vs-td>
                                            <vs-td class="text-center">

                                                    <vx-tooltip text="Bisher nicht zu Bank-Transaktion zugewiesen">
                                                        <vs-icon icon="close"
                                                                 v-if="!editedDocument.bank_transaction_id"></vs-icon>
                                                    </vx-tooltip>

                                                    <vx-tooltip  text="Bereits einer Bank Transaktion zugewiesen">
                                                        <vs-icon icon="done"
                                                                 v-if="editedDocument.bank_transaction_id"></vs-icon>
                                                    </vx-tooltip>

                                            </vs-td>
                                            <vs-td>{{ formatDateTime(editedDocument.created_at)   }}</vs-td>
                                            <vs-td>
                                                <router-link :to="'/document-management/'+editedDocument.id" target="_blank">öffnen</router-link>
                                            </vs-td>
                                        </vs-tr>


                                        <vs-tr
                                                v-for="(document, i) in data" :key="i"
                                                :data="document"
                                        >
                                            <vs-td></vs-td>
                                            <vs-td>{{ document.id }}</vs-td>
                                            <vs-td>{{ document.client_id ? document.client.company_name : 'Keine Firma zugeordnet'  }}</vs-td>
                                            <vs-td>{{ document.document_date ? formatDate(document.document_date) : 'Kein Datum hinterlegt'  }}</vs-td>
                                            <vs-td>{{ document.sum_netto ? floatPointToCommaCurrency(document.sum_netto) : '-'   }} / {{ document.sum_brutto ? floatPointToCommaCurrency(document.sum_brutto) : '-'   }}  </vs-td>

                                            <vs-td class="text-center">
                                                <vx-tooltip text="Bisher nicht zu Bank-Transaktion zugewiesen">
                                                    <vs-icon icon="close"
                                                             v-if="!document.bank_transaction_id"></vs-icon>
                                                </vx-tooltip>

                                                <vx-tooltip text="Bereits einer Bank Transaktion zugewiesen">
                                                    <vs-icon icon="done"
                                                             v-if="document.bank_transaction_id"></vs-icon>
                                                </vx-tooltip>
                                            </vs-td>
                                            <vs-td>{{ formatDateTime(document.created_at)   }}</vs-td>
                                            <vs-td>
                                                <router-link :to="'/document-management/'+document.id" target="_blank">öffnen</router-link>
                                            </vs-td>
                                        </vs-tr>
                                    </template>
                                </vs-table>

                            </vs-popup>
                        </vs-col>
                    </vs-row>

                    <vs-row>
                        <vs-col vs-w="6" class="pr-4">
                            <vs-input label="Summe netto" name="sum_netto" v-model="editedDocument.sum_netto" :disabled="editedDocument.bank_transaction_id != null"  autocomplete="off" class="w-full" data-vv-as="Summe netto" v-validate="'required'"  icon="euro_symbol" icon-after="true" />
                            <span class="text-danger text-sm" v-if="errors.has('sum_netto')">{{ errors.first('sum_netto') }}</span>
                        </vs-col>
                        <vs-col vs-w="6" class="">
                            <vs-input label="Summe brutto" name="sum_brutto" v-model="editedDocument.sum_brutto" :disabled="editedDocument.bank_transaction_id != null"  autocomplete="off" class="w-full" data-vv-as="Summe brutto" v-validate="'required'"  icon="euro_symbol" icon-after="true" />
                            <span class="text-danger text-sm" v-if="errors.has('sum_brutto')">{{ errors.first('sum_brutto') }}</span>
                        </vs-col>
                    </vs-row>

                  <vs-row class="mt-5 mb-5">
                    <vs-col vs-w="6" class="pr-4">
                      <vs-input label="Leistungszeitraum Start" name="service_period_start" v-model="editedDocument.service_period_start" autocomplete="off" class="w-full" data-vv-as="Leistungszeitraum Start" v-validate="'date_format:dd.MM.yyyy'"  />
                      <span class="text-danger text-sm" v-if="errors.has('service_period_start')">{{ errors.first('service_period_start') }}</span>
                    </vs-col>
                    <vs-col vs-w="6" class="">
                      <vs-input label="Leistungszeitraum Ende" name="service_period_end" v-model="editedDocument.service_period_end" autocomplete="off" class="w-full" data-vv-as="Leistungszeitraum Ende" vv-validate="'date_format:dd.MM.yyyy'"  />
                      <span class="text-danger text-sm" v-if="errors.has('service_period_end')">{{ errors.first('service_period_end') }}</span>
                    </vs-col>
                  </vs-row>

                  <vs-row>
                    <vs-col vs-w="6" class="pr-4">
                      <vs-input label="Fällig bis" name="due_on" v-model="editedDocument.due_on" class="w-full" autocomplete="off" data-vv-as="Fällig bis" v-validate="'date_format:dd.MM.yyyy'"  />
                      <span class="text-danger text-sm" v-if="errors.has('due_on')">{{ errors.first('due_on') }}</span>
                    </vs-col>
                    <vs-col vs-w="6" class="pt-8">
                      <vs-checkbox  name="service_period_end" v-model="editedDocument.to_pay" class="w-full" ><small>Für nächsten Rechnungslauf vormerken</small></vs-checkbox>
                      <span class="text-danger text-sm" v-if="errors.has('to_pay')">{{ errors.first('to_pay') }}</span>
                    </vs-col>
                  </vs-row>



                  <vs-row class="mt-4 pb-2">
                    <vs-col vs-w="12">
                      <mg-select v-model="editedDocument.client_id" :options="clients" name="client_id"
                                 placeholder="Zuordnung zum Lieferant/Kunde"
                                 track-by="id" label="company_name" data-vv-as="Kunde/Lieferant" @select="selectClient">
                      </mg-select>
                      <span @click="isCreateClientModalOpen = true" class="create-client-link">Neuer Lieferant erstellen</span>
                      <span @click="openClientLastDocumentsModal" v-if="editedDocument.client_id" class="show-last-client-documents-link">Letzte Dokumente ansehen</span>
                    </vs-col>
                  </vs-row>

                  <last-client-bank-documents-modal :active.sync="isLastClientDocumentsModalOpen" @close="closeBankTransferModal()"
                                         :document.sync="editedDocument"></last-client-bank-documents-modal>

                  <create-client-modal :active="isCreateClientModalOpen"
                                       @close="onCreateClientModalClose" @accept="fetchClients"></create-client-modal>


                    <vs-row v-if="editedDocument.to_pay">

                    <vs-alert color="#7795D1" :active="true" icon="info" class="mb-4 mt-4" v-show="selectedClient && !selectedClient.bank_iban && !editedDocument.iban">
                      Lieferant hat keine IBAN/BIC hinterlegt, bitte händisch eingeben.
                    </vs-alert>

                    <vs-alert color="#7795D1" :active="true" icon="info" class="mb-4 mt-4"
                              v-show="selectedClient && selectedClient.bank_iban != '' && editedDocument.bank_ibaniban != '' && selectedClient.bank_iban != editedDocument.iban">
                    Die untenstehende IBAN oder BIC ist unterschiedlich zu der beim Kunden hinterlegten Bankdaten. Bitte prüfen.
                    </vs-alert>

                    <vs-col vs-w="6" class="pr-4" v-if="editedDocument.client_id && selectedClient">
                      <vs-input label="IBAN" name="iban" v-model="editedDocument.iban" class="w-full " data-vv-as="IBAN"  />
                      <small class="link" @click="insertBankDataFromClient()" v-show="selectedClient && selectedClient.bank_iban && !editedDocument.iban">
                        <feather-icon icon="ChevronRightIcon" class="w-3 h-3 pt-3" />IBAN und BIC vom Kunde einfügen
                      </small>
                    </vs-col>
                    <vs-col vs-w="6" v-if="editedDocument.client_id && selectedClient">
                      <vs-input label="BIC" name="bic" v-model="editedDocument.bic" class="w-full" data-vv-as="IBAN"  />
                    </vs-col>
                  </vs-row>

                    <vs-row class="mt-4">
                        <vs-col vs-w="12" v-if="!showComplexCostCenterCalc ">
                            <mg-select v-model="editedDocument.cost_center[0].cost_center_id" :options="bankTransactionCategories" name="category_id"
                                       placeholder="Zuordnung zur Kostenstelle"
                                       track-by="id" label="name" data-vv-as="Buchhaltungskonto" >
                            </mg-select>
                            <span @click="toggleComplexCostCenters" class="toggleComplexCostCenter">Komplexere Zuordnung</span>
                        </vs-col>
                        <vs-col vs-w="12" class="mt-3 mb-3" v-else>
                            <b>Komplexe Kostenstellenzuordnung</b>
                            <span @click="toggleComplexCostCenters" class="toggleComplexCostCenter d-block" v-if="editedDocument.cost_center.length <= 1">Einfache Zuordnung</span>
                            <vs-row>
                                <vs-col vs-w="3">
                                  Summe (netto)
                                </vs-col>
                                <vs-col vs-w="7">
                                   Kostenstelle
                                </vs-col>
                                <vs-col vs-w="2">

                                </vs-col>
                            </vs-row>
                            <vs-row v-for="(cost_center, index) in editedDocument.cost_center"  :key="index">
                                  <vs-col vs-w="3" class="pr-2">
                                      <vs-input class="w-full" v-model="cost_center.sum" @input="validateNumericInput(index)"></vs-input>
                                  </vs-col>
                                  <vs-col vs-w="7">
                                      <mg-select v-model="cost_center.cost_center_id" :options="bankTransactionCategories"
                                                 name="cost_center_id"
                                                 placeholder="Zuordnung zur Kostenstelle"
                                                 track-by="id" label="name" data-vv-as="Buchhaltungskonto">
                                      </mg-select>
                                  </vs-col>
                                <vs-col vs-w="2">
                                    <span class="category-item__label-delete text-error" @click="deleteCostCenter(cost_center.id)">
                                        <vs-icon icon="close" class="mt-4 ml-2"></vs-icon>
                                    </span>
                                </vs-col>
                            </vs-row>
                            <div>
                                <span class="text-warning" v-if="costcenterSum > editedDocument.sum_netto">{{parseCurrency(editedDocument.sum_netto - costcenterSum)}} zu viel zugeordnet</span>
                                <span class="text-warning" v-if="costcenterSum < editedDocument.sum_netto">{{parseCurrency(editedDocument.sum_netto - costcenterSum)}} zu wenig zugeordnet</span>
                                <span class="text-success" v-if="costcenterSum == editedDocument.sum_netto">Perfekt, {{parseCurrency(costcenterSum)}} zugeordnet</span>
                            </div>
                            <vs-row>
                                <vs-button color="dark" type="filled" class="mr-3 mb-auto" size="small" @click="addEmptyCostCenter">
                                    Neue Aufteilung
                                </vs-button>
                            </vs-row>

                        </vs-col>
                    </vs-row>

                    <vs-row class="mt-4">
                        <vs-col vs-w="12">
                            <vs-textarea label="Buchungstext (Kommentar)"  name="notice" v-model="editedDocument.notice" class="w-full" data-vv-as="Kommentar/Matchcode"/>
                          <span @click="setBillingTransferNotice" class="generateLink">Automatisch generieren</span>
                        </vs-col>
                    </vs-row>

                  <div v-if="editedDocument.bank_transaction_transfer_id && editedDocument.bank_transaction_transfer">
                    <vs-alert color="success" :active="true" icon="info" class="mb-4 mt-4">
                      Dieses Dokument wurde überwiesen
                    </vs-alert>

                    <table class="mb-4">
                      <tr>
                        <td class="font-bold pr-4">Überwiesen am:</td>
                        <td v-if="editedDocument.bank_transaction_transfer && editedDocument.bank_transaction_transfer.sepa_xml_export_date != null">{{ editedDocument.bank_transaction_transfer.sepa_xml_export_date }}</td>
                        <td v-else>Aktuell im nächsten Rechnungslauf</td>
                      </tr>
                      <tr>
                        <td class="font-bold pr-4">Betrag:</td>
                        <td>{{ editedDocument.bank_transaction_transfer.sum }}€ <span style="color:red;" v-if="editedDocument.sum_brutto != editedDocument.bank_transaction_transfer.sum"> UNTERSCHIED: {{editedDocument.sum_brutto - editedDocument.bank_transaction_transfer.sum}}</span></td>
                      </tr>
                      <tr>
                        <td class="font-bold pr-4">Buchungstext</td>
                        <td >{{ editedDocument.bank_transaction_transfer.reference_text }}</td>
                      </tr>
                      <tr>
                        <td class="font-bold pr-4">Interner Notiz</td>
                        <td >{{ editedDocument.bank_transaction_transfer.internal_notice }}</td>
                      </tr>
                      <tr v-if="editedDocument.bank_transaction_transfer.due_from">
                        <td class="font-bold pr-4">Überweisen ab</td>
                        <td >{{ editedDocument.bank_transaction_transfer.due_from }}</td>
                      </tr>
                      <tr>
                        <td class="font-bold pr-4">Überweisung erstellt am</td>
                        <td >{{ editedDocument.bank_transaction_transfer.created_at }}</td>
                      </tr>
                    </table>
                  </div>

                    <div class="mt-2 mb-4" v-if="editedDocument.bank_transaction_id != null">
                        <div>
                          <h2>Zugeordnete Bank-Transaktion</h2>
                            <table class="mb-4">
                                <tr>
                                    <td class="font-bold pr-4">ID:</td>
                                    <td>{{ editedDocument.bank_transaction.id }}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold pr-4">Datum:</td>
                                    <td>{{ formatDate(editedDocument.bank_transaction.booking_date) }}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold pr-4">Client:</td>
                                    <td>{{ editedDocument.bank_transaction.client }}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold pr-4">Betrag:</td>
                                    <td><span v-if="editedDocument.bank_transaction.booking_type == '-'">-</span>{{ editedDocument.bank_transaction.sum }}€ </td>
                                </tr>
                                <tr>
                                    <td class="font-bold pr-4">Verwendungszweck</td>
                                    <td >{{ editedDocument.bank_transaction.reference_text }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <router-link :to="'/bank-transactions/'+formatYear(editedDocument.bank_transaction.booking_date) +'/'+ formatMonth(editedDocument.bank_transaction.booking_date)" target="_blank">
                                            <b>Bank Zuordnung öffnen</b>
                                        </router-link>
                                    </td>

                                </tr>

                            </table>
                        </div>
                    </div>

                  <div class="mt-2 mb-4" v-if="!editedDocument.bank_transaction_transfer_id">
                    <div>
                      <vs-alert color="#e24733" :active="true" icon="info" class="mb-4 mt-4">
                        Keine Überweisung zu diesem Beleg gefunden!
                      </vs-alert>
                    </div>
                  </div>

                  <create-transfer-modal :active.sync="openBankTransferModalActive" @close="closeBankTransferModal()" @saved="closeBankTransferModal(true)"
                                         :document.sync="editedDocument"></create-transfer-modal>

                  <div class="mt-2 mb-4" v-if="editedDocument.bill_mail_archiv_id">
                    <div>
                      <vs-alert color="info" :active="true" icon="info" class="mb-4 mt-4">
                       Diese Rechnung ist mit einer E-Mail aus dem Rechnungspostfach verknüpft. ID = {{ editedDocument.bill_mail_archiv_id }}
                      </vs-alert>
                    </div>
                  </div>


                    <history :histories="editedDocumentHistory" :default-opened="false" class="mt-4"></history>


                    <div class="flex mt-8 justify-end">

                      <vs-button color="danger" type="border" icon="delete" size="small" class="mr-auto" @click="deleteDocument">Dokument löschen</vs-button>
                      <div class="save-document-button mt-2" >

                        <vs-dropdown class="vs-button vs-button-success vs-button-filled">
                          <div class="add-body">
                            <feather-icon icon="SaveIcon" class="mr-2"/>
                           Speichern
                          </div>

                          <vs-dropdown-menu>
                            <vs-dropdown-item @click="saveDocumentDataAndPay">
                              Speichern und überweisen <span class="text-error" v-if="!editedDocument.document_number || !editedDocument.client_id  || !editedDocument.sum_brutto">! Daten fehlen !</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="saveDocumentData">
                             Nur Speichern
                            </vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </div>



                    </div>

                </div>

            </vx-card>
        </vs-col>

      <mysql-selection-popup :active="activeMysqlSelectionPrompt"
                             @close="onMysqlSelectionPromptClose" @accept="fetchData"></mysql-selection-popup>
    </vs-row>


</template>

<script>
    import ApiService from "../../api";
    import moment from 'moment';
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import MgSelect from "../../components/mg-select/MgSelect";
    import QueryHelper from "../../mixins/helper/query.helper";
    import findIndex from "lodash/findIndex";
    import PriceHelper from "../../mixins/helper/price.helper";
    import MultipleFileUploader from  '@/plugins/vue2-multi-uploader/src/MultipleFileUploader';
    import * as PDFObject from 'pdfobject';
    import MysqlSelectionPopup from "../../components/mysql-connection-popup/MysqlSelectionPopup";


    var qs = require('qs');

    import vSelect from 'vue-select'
    import {mapGetters} from "vuex";
    import CreateTransferModal from "@/components/billing-transfer/create-transfer-modal";
    import CreateClientModal from "@/components/client/CreateClientModal";
    import LastClientBankDocumentsModal from "@/components/client/LastClientBankDocumentsModal";
    import History from "@/components/history/History.vue";

    export default {
        components: {
            History,
          CreateClientModal,
          CreateTransferModal,
            DateRangePicker,
          MgSelect,
            MultipleFileUploader,
            'v-select': vSelect,
          MysqlSelectionPopup,
          LastClientBankDocumentsModal
        },
        data() {
            return {
                editedDocumentHistory: [],
                isDuplicatePopupOpen: false,
                showComplexCostCenterCalc: false,
                isLastClientDocumentsModalOpen: false,
                isCreateClientModalOpen: false,
                openBankTransferModalActive: false,
                activeMysqlSelectionPrompt: false,
                bankTransactionCategories: [],
                selectedClient: [],
                clients: [],
                documents: [],
                documentQueryParams: {
                    sort: [],
                    filters: [],
                    global_search: "",
                    per_page: 500,
                    page: 1,
                },
                with_finished_documents: false,
                total_rows: 0,
                selectedForDialog: null,
                categories: [],
                searchInput: null,
                opens: true,
                requestedInvoiceMonth:null,
                requestedInvoiceYear:null,
                optionsMonth: {"01": "01", "02": "02", "03": "03", "04": "04"},

                dateRange: {
                    startDate: null,
                    endDate: null
                },
                editedDocument:[],
                documentDuplicates:[],
            }
        },
        computed: {
            ...mapGetters(['userHasPermission']),
            duplicateDocumentNumbersCount(){
              if (!this.editedDocument || this.documentDuplicates && this.documentDuplicates.length === 0)  {
                  return 0;
              }
                // Filtere das Array documentDuplicates, um nur die Duplikate zu behalten
                const duplicateDocuments = this.documentDuplicates.filter(document => document.id !== this.editedDocument.id);

                // Gib die Menge der verbleibenden Duplikate zurück
                return duplicateDocuments.length || 0 ;
            },duplicateDocumentNumbers(){
              if (!this.editedDocument || this.documentDuplicates && this.documentDuplicates.length === 0)  {
                  return [];
              }
                return  this.documentDuplicates.filter(document => document.id !== this.editedDocument.id);
            },
            documentHistoryRequestParams() {
                return {
                    order: {
                        'field': 'created_at',
                        'direction': 'DESC'
                    }
                }
            },
            requestDocumentParams() {
                let queryParams = QueryHelper.parseRequestParams(this.documentQueryParams);

                if(!this.with_finished_documents) {
                    queryParams.filter.push({
                        field: 'bank_transaction_id',
                        value: 'null',
                        expression: 'exact'
                    });
                }

                if(this.dateRange.startDate != null ) {
                    queryParams.filter.push({
                        field: 'document_date',
                        value:  moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                        expression: 'greaterAndExact'
                    });
                    queryParams.filter.push({
                        field: 'document_date',
                        value:  moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                        expression: 'lessAndExact'
                    });
                }

                if(this.searchInput != null && this.searchInput.length >= 1 ) {
                    queryParams.filter.push({
                        expression: 'group',
                        filter: [
                            {
                                field: 'document_number',
                                value: this.searchInput,
                                expression: 'contains',
                                operator: 'or'

                            },
                            {
                                field: 'sum_netto',
                                value: this.searchInput,
                                expression: 'contains',
                                operator: 'or'
                            },
                            {
                                field: 'sum_brutto',
                                value: this.searchInput,
                                expression: 'contains',
                                operator: 'or'
                            },
                            {
                                field: 'notice',
                                value: this.searchInput,
                                expression: 'contains',
                                operator: 'or'
                            },
                            {
                                field: 'original_filename',
                                value: this.searchInput,
                                expression: 'contains',
                                operator: 'or'
                            },
                            {
                              field: 'search_keywords',
                              value: this.searchInput,
                              expression: 'contains',
                              operator: 'or'
                            }
                        ]
                    });
                }


                queryParams.order =
                    {
                        'field': 'created_at',
                        'direction': 'desc'
                    }
                ;

                return queryParams;
            },
            costcenterSum(){
                if (
                    this.editedDocument &&
                    this.editedDocument.cost_center &&
                    Array.isArray(this.editedDocument.cost_center)
                ) {
                    return this.editedDocument.cost_center.reduce(
                        (totalSum, costCenter) => totalSum + parseFloat(costCenter.sum),
                        0
                    );
                }
                return 0;


            },
            currentEditingDocumentId(){
              if (!this.$route.params.fileId || this.$route.params.fileId === 'undefined') {
                  return null;
              }
                return this.$route.params.fileId;
            }

        },
        mounted() {

            if(!this.userHasPermission('view_bank_transaction_documents')) {
                this.$vs.notify({
                    title: 'Keine Berechtigung',
                    text: 'Für diese Seite hast du keine Berechtigung',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
                this.$router.push(`/`);
            }
            if(this.userHasPermission('view_bank_transaction_documents')) {
                this.fetchData();
            }

            if(this.$route.params.fileId) {
                this.fetchOneDocument(this.$route.params.fileId);
            }
        },
        methods: {
            toggleComplexCostCenters(){
                this.showComplexCostCenterCalc = !this.showComplexCostCenterCalc;
            },
            fetchData() {
                this.$vs.loading();

                let bankTransactionsDocumentsPromise = ApiService.get('bank-transactions/document', {
                    params: this.requestDocumentParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                });

                Promise.all([bankTransactionsDocumentsPromise])
                    .then(([bankTransactionsDocuments]) => {
                        this.documents = bankTransactionsDocuments.data.result;
                        this.$vs.loading.close();

                    }).catch((response) => {

                  this.$vs.loading.close();
                  this.activeMysqlSelectionPrompt = true;

                });

              ApiService.get('cost-center').then(response => {
                if (response.status !== 200) {
                  return;
                }
                this.bankTransactionCategories = response.data.result.result;
              })

             this.fetchClients()
            },
            fetchClients(){
              ApiService.get('clients').then(response => {
                if (response.status !== 200) {
                  return;
                }
                this.clients = response.data.result;
              })
            },
            fetchOneDocument(id) {
                this.$vs.loading();
                const version = Math.floor((Math.random() * 1000000) + 1);
                let bankTransactionsDocumentPromise = ApiService.get('bank-transactions/document/' + id);

                Promise.all([bankTransactionsDocumentPromise])
                    .then(([bankTransactionsDocument]) => {
                        this.editedDocument = bankTransactionsDocument.data.result;
                        this.editedDocument.document_date = this.formatEUDate(this.editedDocument.document_date);
                        this.editedDocument.due_on = this.formatEUDate(this.editedDocument.due_on);

                        if (this.editedDocument.client_id) {
                          this.selectedClient = this.editedDocument.client
                        }

                        if (this.editedDocument.cost_center.length >= 2) {
                          this.showComplexCostCenterCalc = true;
                        }
                        if(this.editedDocument.cost_center.length == 0){
                            this.addEmptyCostCenter()
                        }
                        this.findDuplicateDocuments()
                        this.fetchDocumentHistory()



                        PDFObject.embed(`${process.env.VUE_APP_PUBLIC_URL}storage/accounting_documents/${this.editedDocument.file}?v=${version}`, '#document-pdf-viewer');

                        this.$vs.loading.close();
                    });

            },
            fetchDocumentHistory() {
                const id = this.$route.params.fileId;
                ApiService.get(`bank-transactions/document/history/${id}`, {
                    params: this.documentHistoryRequestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    if (response.status !== 200) {
                        return;
                    }
                    this.editedDocumentHistory = response.data.result;
                });
            },
            generateSepaXMLPrompt(){
              this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: 'SEPA XML Download',
                text: 'Bist du dir sicher, dass du die aktuelle SEPA XML downloaden möchtest? Du musst wissen, das alle Überweisungen dann auf erledigt gesetzt werden und in der nächsten SEPA-Datei nicht mehr erscheinen.',
                accept: this.downloadSepaXML,
                acceptText: 'Ja, SEPA XML generieren',
                cancelText: 'Abbrechen'
              })
            },
          downloadSepaXML(){
              ApiService.get('bank-transactions/document/billing-run',{ responseType: 'blob' }).then(response => {

                let blob = new Blob([response.data], { type: 'application/xml' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'mediagraphik-sepa.xml'
                link.click()

              })
            },

            formatDateTime(value) {
                return moment(new Date(value)).format('DD.MM.YYYY - H:m')
            },formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            formatYear(value) {
                return moment(new Date(value)).format('YYYY')
            },
            formatMonth(value) {
                return moment(new Date(value)).format('MM')
            },
            formatEUDate(value) {
              if(value == null || !value) {
                return null;
              }
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            formatUSDate(value) {
                return moment(value, "DD.MM.YYYY").format('YYYY-MM-DD')
            },
            setBillingTransferNotice() {
              if (!this.editedDocument.document_number || !this.editedDocument.document_date) {
                this.editedDocument.notice = "!!!!  Rechnungsnummer und/oder Rechnungsdatum fehlt !!!!"
              } else {
                this.editedDocument.notice = "Rechnung Nr. "+this.editedDocument.document_number+" vom "+ this.editedDocument.document_date
              }
            },
            parseCurrency(value){
                return PriceHelper.formatCurrency(value)
            },
            floatPointToCommaCurrency(value){
                return PriceHelper.floatPointToComma(value)
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
            loadMoreData(value) {
                this.config.per_page = this.config.per_page + value;
            },
            updateValues(data) {
                this.$emit('update-date-range', {
                    start: moment(data.startDate).format('YYYY-MM-DD'),
                    end: moment(data.endDate).add(1, 'day').format('YYYY-MM-DD')
                });
                this.fetchData();
            },
            checkOpen(data) {
            },
            closeBankTransferModal(saved = false) {
              this.openBankTransferModalActive = false;
              if (saved) {
                this.fetchOneDocument(this.$route.params.fileId)
              }

            },
            findDuplicateDocuments() {
                if(!this.editedDocument && !this.editedDocument.document_number && this.editedDocument.document_number.length === 0 ) {
                    this.documentDuplicates = [];
                    return;
                }
                const documentNumber = this.editedDocument.document_number;
                ApiService.get('bank-transactions/document?filter[document_number]=' + documentNumber ).then(response => {
                    if (response.status !== 200) {
                        return;
                    }
                    this.documentDuplicates = response.data.result;
                })

            },
            onCreateClientModalClose() {
              this.isCreateClientModalOpen = false;
            },
            openClientLastDocumentsModal() {
              this.isLastClientDocumentsModalOpen = true;
            },
            clearDateRange() {
                this.dateRange = {
                    startDate: null,
                    endDate: null
                };

                this.$emit('update-date-range', {
                    start: null,
                    end: null
                });

                this.fetchData()
            },
            deleteDocument() {
              if(this.editedDocument.length != 0) {
                let payload = JSON.parse(JSON.stringify(this.editedDocument));
                if(payload.bank_transaction_id ) {
                  return this.$vs.notify({
                    title: 'Fehler',
                    text: 'Das geht nicht, es besteht schon eine Zuweißung zu einer Bank-Transaktion',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  })
                }
                // check if payload.bank_transaction_transfer is a array with value


                if(payload.bank_transaction_transfer_id && Array.isArray(payload.bank_transaction_transfer)) {
                  return this.$vs.notify({
                    title: 'FEHLER!!',
                    text: 'Das geht nicht, es besteht schon eine Zuweißung zu einer Überweisung',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  })

                }
                ApiService.delete('bank-transactions/document/' + payload.id)
                    .then(response => {
                      if (response.data.status === "success") {

                        this.fetchData()
                        this.$vs.notify({
                          title: 'Erfolgreich',
                          text: 'Dokument gelöscht',
                          iconPack: 'feather',
                          icon: 'icon-alert-circle',
                          color: 'success'
                        })
                      }
                    })
                    .catch((response) => {
                      this.$vs.notify({
                        title: 'Fehler',
                        text: response.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                      })
                    });
              } else {
                this.$vs.notify({
                  title: 'FEHLER!!',
                  text: 'Kein Dokument ausgewählt',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'error'
                })
              }
            },

          getOutgoingInvoice(){

              if(!this.requestedInvoiceMonth || !this.requestedInvoiceYear) {
                return this.$vs.notify({
                  title: 'Fehler',
                  text: "Bitte Zeitraum auswählen",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              }

            this.$vs.loading();

            ApiService.get('bank-transactions/document/insert-payed-invoices/'+ this.requestedInvoiceYear +'/'+this.requestedInvoiceMonth).then(response => {
              console.log(response.data.total)
              if (response.status !== 200 || response.data.total === 0 ) {
                this.$vs.loading.close()

                return this.$vs.notify({
                  title: 'Fehler',
                  text: "Unbekannter Fehler oder keine bezahlten Rechnungen in diesem Zeitraum.",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
              }

              if (response.data && response.data.errors && response.data.errors.length >= 1 ) {
                for (let i = 0; i < response.data.errors.length; ++i) {
                  console.log(response.data.errors)
                  this.$vs.notify({
                    title: 'Fehler',
                    text: response.data.errors[i],
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  })
                }
              }

              this.$vs.notify({
                text: response.data.total + 'x Ausgangsrechnungen wurde erfolgreich in das Belegmanagement überführt.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              });

              this.fetchData()
              this.$vs.loading.close()
            })
            this.$vs.loading.close()

          },
            saveDocumentData() {
                if(this.editedDocument.length != 0) {

                    this.deleteEmptyCostCenter();

                    let payload = JSON.parse(JSON.stringify(this.editedDocument));

                    if(!payload.document_date || payload.document_date.length == 0 ) {
                      this.$vs.notify({
                        title: 'Warnung',
                        text: 'Es fehlt ein Pflichtfeld: Belegdatum',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'warning'
                      })
                    }

                  if(!payload.client_id ) {
                    this.$vs.notify({
                      title: 'Warnung',
                      text: 'Es fehlt ein Pflichtfeld: Lieferant/Kunde',
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'warning'
                    })
                  }

                  if(!payload.document_number ) {
                    this.$vs.notify({
                      title: 'Warnung',
                      text: 'Es fehlt ein Pflichtfeld: Belegnummer',
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'warning'
                    })
                  }
                  if(!payload.sum_brutto ) {
                    this.$vs.notify({
                      title: 'Warnung',
                      text: 'Es fehlt ein Pflichtfeld: Summe brutto',
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'warning'
                    })
                  }




                    payload.document_date = !payload.document_date || payload.document_date.length == 0 ? null : this.formatUSDate(payload.document_date);
                    payload.sum_brutto =   payload.sum_brutto == "0.00" ||  payload.sum_brutto == "0,00" || !payload.sum_brutto ? null : parseFloat(PriceHelper.parseComma(payload.sum_brutto)).toFixed(2);
                    payload.sum_netto =   payload.sum_netto == "0.00" || payload.sum_netto == "0,00" || !payload.sum_netto ? null : parseFloat(PriceHelper.parseComma(payload.sum_netto)).toFixed(2);
                    payload.due_on =   !payload.due_on || payload.due_on.length == 0  ? null : payload.due_on;

                    if(payload.iban && payload.iban.length > 2) {
                      payload.iban =   payload.iban.replace(/\s/g, '');
                    }
                  if(payload.bic && payload.bic.length > 2) {
                    payload.bic =   payload.bic.replace(/\s/g, '');
                  }


                    return ApiService.put('bank-transactions/document/' + this.$route.params.fileId, payload)
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.fetchData();
                                this.fetchOneDocument(this.$route.params.fileId);
                                return this.$vs.notify({
                                    title: 'Erfolgreich',
                                    text: 'Dokument erfolgreich bearbeitet',
                                    iconPack: 'feather',
                                    icon: 'icon-alert-circle',
                                    color: 'success'
                                })
                            }

                            this.$vs.notify({
                                title: 'Fehlgeschlagen',
                                text: 'Dokument konnte nicht bearbeitet werden',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        })
                        .catch((error) => {
                            this.$vs.notify({
                                title: 'Fehlgeschlagen',
                                text: 'Dokument konnte nicht bearbeitet werden',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        })
                }

            },
            saveDocumentDataAndPay() {
              this.openBankTransferModalActive = true
              this.editedDocument.to_pay = true
              this.saveDocumentData();
            },
            editDocumentDetails(file) {
                this.editedDocument = file;
            },
            closeDocumentDetails() {
                this.editedDocument = [];
            },
            selectClient(value) {
              this.selectedClient = value

              if (!this.editedDocument.iban && value.bank_iban) {
                this.editedDocument.iban = value.bank_iban
              }
              if (!this.editedDocument.bic && value.bank_bic) {
                this.editedDocument.bic = value.bank_bic
              }
            },
            insertBankDataFromClient(){
              if (!this.editedDocument.iban && this.selectedClient.bank_iban) {
                this.editedDocument.iban = this.selectedClient.bank_iban
              }
              if (!this.editedDocument.bic && this.selectedClient.bank_bic) {
                this.editedDocument.bic = this.selectedClient.bank_bic
              }
            },
          onMysqlSelectionPromptClose() {
            this.activeMysqlSelectionPrompt = false;
            this.fetchData();
          },
            deleteEmptyCostCenter() {
                const index = this.editedDocument.cost_center.findIndex(costCenter => costCenter.cost_center_id === null);

                if (index !== -1) {
                    this.editedDocument.cost_center.splice(index, 1);
                }
            },
          deleteCostCenter(id) {
              const index = this.editedDocument.cost_center.findIndex(costCenter => costCenter.id === id);

              if (index !== -1) {
                  this.editedDocument.cost_center.splice(index, 1);
              }
            },
            addEmptyCostCenter(){
                const emptyCostCenter = {
                    cost_center_id:null,
                    sum: this.editedDocument.sum_netto - this.costcenterSum,
                    bank_transaction_id: null,
                    bank_transaction_document_id:this.editedDocument.id
                };


                this.editedDocument.cost_center.push(emptyCostCenter);
            },
            validateNumericInput(index) {
                this.editedDocument.cost_center[index].sum = this.editedDocument.cost_center[index].sum.replace(/[^0-9.]/g, '');
            },
            openDuplicatePopup(){
                this.isDuplicatePopupOpen = true;
            },
            onCloseDuplicatePopup() {
                this.isDuplicatePopupOpen = false;
            },


        },
        filters: {
            date: function (value) {
                if(value == null) return '';
                var f = function(d) { return d < 10 ? '0' + d : d; };
                return f(value.getDate()) + '.' + f(value.getMonth() + 1) + '.' + value.getFullYear();
            }
        },
        watch:{
            '$route' (to, from){

               if(to.params.fileId) {
                   this.$vs.loading();
                   this.fetchOneDocument(to.params.fileId)
                   this.$vs.loading.close();
               }
            },
        },
    }

</script>

<style lang="scss">
    /* Bootstrap 4 text input with clear icon on the right */

    .has-clear-right {
        position: relative;
    }

    .has-clear-right .form-control {
        //padding-right: 2.375rem;
    }

    .has-clear-right .form-control-feedback {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
    }

    .has-clear-right .form-control-feedback:hover {
        color: red;
    }

    .vue-daterange-picker {
        width: 81%;
        float: left;
        margin-right: 5px;
    }

    .daterangepicker .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .has-clear-right > input {
        width: 80%;
        float: left;
    }

    .currency > div {
        text-align: right;
        width: 100%;
    }
    .currency .plus {
        color: green;
        font-weight: bold;
        font-size: 15px;
    }
    .result-container {
        border-top: 1px solid #ecebeb;
        margin-top: 15px;
        padding-top: 15px;
    }


    .document-item {

        text-align: center;
        overflow: hidden;
    }

    .pdfobject-container { width: 100%; height: 350px }

    .image-container img {
        max-width: 100%;
        height: auto;
    }

    .image-container {
        max-width:100%;
        height: 220px;
        overflow: hidden;
    }
    .badges-container {
        position: absolute;
    }

    .badges-container .vs-icon {
        font-size: 32px;
    }

    a.document-link > div {
        border: 1px dashed #d0d0d0;
        height: 300px;
        overflow: hidden;
    }



    span.filename {
        display: block;
    }

    i.sum {
        margin-top: 10px;
        display: block;
        color: black;
        font-weight: 600;
    }

    .badges-container .check_circle {
        color: #10cc10;
    }

    .link {
      color: #e24733;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }

    .link .feather-icon {
      float: left;
    }

    .save-document-button {


    .vs-con-dropdown.vs-button {
      width: 180px;
      height: 40px;
    }

    span.feather-icon {
      top: 6px;
    }

    .add-body {margin-top: -6px;}


    }
    .con-vs-dropdown--menu {
      width: 280px;
    }
    .text-error {
      color:red;
      font-style: italic;
      font-size: 10px;
    }

    span.generateLink {
      color: #25262b;
      font-size: 12px;
      cursor: pointer;
      top: -16px;
      position: relative;
    }

    span.generateLink:hover {
      text-decoration: underline;
      color: #71c73f;
    }
    span.create-client-link {
      font-size: 11px;
      position: absolute;
      color: #e24733;
      text-decoration: underline;
      cursor: pointer;
    }
    .show-last-client-documents-link {
      font-size: 11px;
      position: absolute;
      color: #e24733;
      text-decoration: underline;
      cursor: pointer;
      right: 20px;
    }
    .toggleComplexCostCenter{
      text-decoration: underline;
      color:#e24733;
      cursor: pointer;
      font-size: 11px;
    }

    div#double-document-numbers-popup--modal .vs-popup {
      width: 960px;
    }
    .bg-green-light {
      background: #aedda0!important;
    }

    .document-item.current-edit-document > a > div {
      border: 3px solid #464646;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

</style>
